import React from "react";

export default function Tab(props: { tokenSet: [string | null, React.Dispatch<React.SetStateAction<string | null>>], subPageId: string, theme: string }) {
  const { tokenSet, subPageId } = {
    ...props,
  };
  const token = tokenSet[0];
  const subPageIdArray = subPageId.split(".");
  const subPage = subPageIdArray[0];
  const subPageTag = subPageIdArray[1];
  let deepLinkLabelString = "";
  deepLinkLabelString += subPage !== "" ? `&portName=${subPage}` : "";
  deepLinkLabelString += subPage !== "" && subPageTag !== "" ? `&portTag=${subPageTag}` : "";
  return (
    token ? <iframe title="cht_portal" allow={"camera *; microphone *"} src={`https://chtportalclient-webapp.azurewebsites.net/?action=login&token=${token}&source=teams${deepLinkLabelString}`} style={{ width: "100%", height: "100vh", border: "0" }} /> : null
  );
}
